import React, { useState, useEffect } from 'react';
import { LandingPageContainer, MylandingPagealign, container } from './styled';
import jwt_decode from 'jwt-decode';
import { getEnvVariables } from '../../../utils/helperFunctions';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Button,
  TextareaAutosize,
  Box,
  Paper,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const styles = {
  container: {
    marginTop: '30px',
    minHieght: '80vh',
  },
  header: {
    color: '#222e45',
  },
  textField: {
    width: '100%',
  },
  button: {
    marginTop: '20px',
    borderRadius: '25px',
    background: 'rgb(29 190 110)',
    color: 'white',
    fontSize: 'larger',
  },
  responseHeader: {
    marginTop: '20px',
  },
  noApiKeyMessage: {
    paddingLeft: '20px',
    color: 'black',
    background: 'white',
    borderRadius: '5px'
  },
};
const PATManagement = ({ flags }) => {
  const { t, i18n } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [token, settoken] = useState(false);
  const [accessToken, setaccessToken] = useState(false);
  const [response, setResponse] = useState(null);
  const [showResponse, setShowResponse] = useState(false);
  const [userId, setuserId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [tenDukeUrl, dataApiUrl] = getEnvVariables();
  const [apikey, setapikey] = useState('');
  const [noapikey, setnoapikey] = useState(false);
  const [issubscribe, setissubscribe] = useState(false);
  const [patName, setPatName] = useState('');
  const [formError, setFormError] = useState(false);
  const [patData, setPatData] = useState('');
  const [deleted, setDeleted] = useState(null);
  const [patExist, setPatExist] = useState(false);
  const [loader, setloader] = useState(true);

  const enableChartFeature = flags.dataapiLandingPage;
  const customTheme = useTheme();
  const background = customTheme.palette.mode === 'light'
    ? '#ffff'
    : '#1f1f1f';
  const color = customTheme.palette.mode === 'dark'
    ? '#ffff'
    : '#1f1f1f';

  const styles = {
    header: {
      color: color,
      paddingBottom: '20px',
    },
    button: {
      borderRadius: '25px',
      background: 'rgb(29 190 110)',
      color: 'white',
      fontSize: 'larger',
      display: 'inline-block',
      padding: '15px',
      margin: '30px auto',
    },
    responseTextarea: {
      width: '692px',
      height: '43px',
      Padding: '20px',
    },
    noApiKeyMessage: {
      paddingLeft: '20px',
      color: 'black',
      background: 'white',
      borderRadius: '10px'
    },
    tokenInfo: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    tokenTitle: {
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    tokenPermissions: {
      fontStyle: 'italic',
      color: '#666',
    },
    deleteButton: {
      marginLeft: '16px',
    },
    lastUsed: {
      fontSize: '12px',
    },
  };
  useEffect(() => {
    let accessToken;
    let token;

    if (typeof window !== 'undefined') {
      accessToken = localStorage.getItem('access_token');
      setaccessToken(accessToken);
      token = localStorage.getItem('id_token');
      settoken(token);
    }
    if (token) {
      const decoded = jwt_decode(token);
      const userId = decoded.sub;
      console.log('userid', userId);
      setuserId(userId);
      setloader(true);


      const getOrgIdUrl = `${tenDukeUrl}/api/idp/v1.exp/users/${userId}/organization-groups`;
      fetch(getOrgIdUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            const orgId = data[0].organizationId;
            console.log(orgId);
            setOrgId(orgId);
            // setResponse(orgId);


            // another api
            const getKeysUrl = `${dataApiUrl}/api/apim/apikeys`;
            fetch(getKeysUrl, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'org_id': orgId,
              }
            })
              .then(response => {
                if (response.ok) {
                  return response.json();
                }
              })
              .then(data => {
                if (data.api_key) {
                  setissubscribe(true);
                }
                else {
                  setissubscribe(false);
                }
              })
              .catch(error => {
                console.error('Error:', error);
              });

            const getpatsUrl = `${dataApiUrl}/api/apim/personal-tokens`;
            fetch(getpatsUrl, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'org_id': orgId,
              }
            })
              .then(response => {
                if (response.ok) {
                  return response.json();
                }
              })
              .then(data => {
                if (data.pat_name) {
                  console.log(data);
                  setPatData(data);
                  setPatExist(true);
                } else {
                  setPatExist(false);
                }
              })
              .catch(error => {
                console.error('Error:', error);
              });
          } else {
            setOrgId('no key found');
            // setResponse('no key found');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      // another api

      setTimeout(() => {
        setloader(false);
      }, 1000);
    }

  }, []);

  const handlePATButtonClick = (e) => {
    e.preventDefault();
    if (patName.trim() === '') {
      setFormError('*PAT name cannot be empty.');
    }
    else {
      const genpatsUrl = `${dataApiUrl}/api/apim/personal-tokens`;
      fetch(genpatsUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'org_id': orgId,
        },
        body: JSON.stringify({ patName: patName })
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          if (data.key) {
            const apiKey = data.key;
            setapikey(apiKey);
            setResponse(apiKey);
          } else {
            setResponse('no key found');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      setShowResponse(true);
      setFormError('');
    }
  };

  const handleokonClick = () => {
    setShowResponse(false);
    window.location.reload();
    // You can proceed with your logic if there are no errors
  };


  const handleDeleteToken = () => {
    const delpatsUrl = `${dataApiUrl}/api/apim/personal-tokens`;
    fetch(delpatsUrl, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'org_id': orgId,
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        if (data.Message) {
          const deletemessage = data.Message;
          setDeleted(deletemessage);
        } else {
          setDeleted('no key found');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    window.location.reload();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(response)
      .then(() => {
        alert('Response copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard:', error);
      });
  };
  // Render the events in your UI


  return (
    <LandingPageContainer background={background}>
      <Typography variant="h4" style={styles.header}>

        {t('patmanage.text1')}
      </Typography>
      <Typography variant="body1" style={styles.header}>

        {t('patmanage.text2')}
      </Typography>
      {/* <Divider variant="middle" /> */}
      {loader ? (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: '200px' }}>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <>
          {formError && !patName && (
            <div style={{ color: 'red' }}>
              {formError}
            </div>
          )}

          {!loader && (  // Wrap the content in this conditional check
            <div style={{ display: 'flex', alignItems: 'stretch', gap: '40px', paddingTop: '30px', paddingBottom: '30px', borderBottom: '10px solid #999', flexFlow: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', alignContent: 'flex-start' }}>
              {!showResponse && issubscribe && !patExist && (
                <> <TextField
                  id="pat-name"
                  placeholder="Enter PAT Name Here"
                  variant="outlined"
                  required
                  value={patName}
                  onChange={(e) => setPatName(e.target.value)}
                  inputProps={{
                    style: { color: 'black' }, // This sets the text color to black
                  }}
                  sx={{ background: 'white' }}
                />

                </>
              )}
              {showResponse && (
                <Typography variant="h6" style={styles.header}>
                  {t('patmanage.namepat')}  {patName}
                </Typography>
              )}
              <div>
                {showResponse && (
                  <>
                    <TextareaAutosize
                      value={response}
                      readOnly
                      style={styles.responseTextarea}
                    />
                    <IconButton
                      color="primary"
                      onClick={handleCopyToClipboard}
                      aria-label="Copy to Clipboard"
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                    <Typography variant="body2" style={styles.header}>
                      {t('patmanage.pleasesave')}

                    </Typography>
                  </>)
                }
              </div>
              {!showResponse && issubscribe && !patExist && (
                <Tooltip title="Generate PAT">
                  <Button
                    variant="contained"
                    onClick={handlePATButtonClick}
                    style={{ textTransform: 'none' }}
                  >
                    {t('patmanage.generatenew')}

                  </Button>
                </Tooltip>
              )}
              {showResponse && (
                <Tooltip title="Generate Ok">
                  <Button
                    variant="contained"
                    onClick={handleokonClick}
                    style={{ textTransform: 'none', width: '100px', height: '40px', }}
                  >
                    {t('patmanage.textok')}

                  </Button>
                </Tooltip>
              )}
            </div>
          )}

          <div>
            {patExist && !loader && (
              <div>
                <Paper variant="outlined" style={{ marginTop: '10px' }}>
                  <Box style={{ display: 'flex', alignItems: 'center', gap: '30px', width: '100%', padding: '10px', }}>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: '30px', width: '120%' }}>
                      <Typography variant="h6" style={styles.tokenTitle}>
                        {patData.pat_name}
                      </Typography>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon fontSize="small" />
                        <span>
                          {t('patmanage.Createdon')}  {new Date(patData.created_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}{' '}
                          {new Date(patData.created_date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })} UTC
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      <Tooltip title="Delete Token">
                        <IconButton
                          color="error"
                          size="large"
                          style={styles.deleteButton}
                          onClick={() => handleDeleteToken()}
                        >
                          <DeleteIcon style={{ fontSize: 'large' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Paper>
              </div>
            )}
          </div>

          {!issubscribe && token && !patExist && !loader && (
            <Paper elevation={3} style={styles.noApiKeyMessage}>
              <Typography variant="body1" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                {t('patmanage.anyvalid')}
              </Typography>
            </Paper>
          )}
        </>
      )}
    </LandingPageContainer>
  );
};

export default withLDConsumer()(PATManagement);