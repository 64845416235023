import SEO from '../components/seo';
import React from 'react';
import PATManagement2 from '../components/PatManage';


const Settings2 = () => {

  return (
    <>
      < SEO title="Data-Api " description="solibri data api" lang="en" />
      <PATManagement2 />
    </>
  );
};


export default Settings2;
