import React from 'react';
import PATManagement from './PageContainer';
import Header from '../Shared/Header/HeaderContainer';
import Footer from '../Shared/Footer/FooterContainer';
import { ThemeProvider } from '../Shared/ThemeProvider/index';

const PATManagement2 = () => {
  return (
    <>
      <ThemeProvider>
        <Header lightLogo={true}/>
        <PATManagement />
        <Footer/>
      </ThemeProvider>
    </>
  );
};

export default PATManagement2;