import styled from 'styled-components';

// const LandingPageContainer = styled.div`
// 	position: relative;
// 	min-height: calc(100vh - 62px - (12px * 4 * 2));
// 	padding-bottom: 48px;
// 	overflow: hidden;
//   background: linear-gradient(180deg, #017EFD 0%, #0174E9 100%);
// 	@media only screen and (min-width: 640px){
// 		min-height: calc(100vh - 62px - (12px * 7 * 2));
// 	}
// `;

const LandingPageContainer = styled.div`
min-height: calc(100vh - 83px - (12px * 4 * 2));
background: ${(props) => props.background};
display: flex;
flex-flow: column;
justify-content: left;
align-items: stretch;
align-content: center;
flex-wrap: nowrap;
padding-left: 160px;
padding-right: 260px;
padding-top: 107px
`;
const container = styled.div`
    background: linear-gradient(to bottom, #398fc8 0%, rgb(57 144 255) 100%);
    display: flex;
    align-items: end;
    flex-wrap: nowrap;
    flex-direction: column;

	@media only screen and (min-width: 640px){
		padding: 36px 36px 72px;
	}
	@media only screen and (min-width: 768px){
		padding: 72px 72px 84px;
	}
`;

const ImageCOntainer = styled.div`
display: flex;
background: linear-gradient(to bottom, #398fc8 0%, rgb(57 144 255) 100%);
align-items: center;
justify-content: center;
    gap: 30px
`;

// const ImageInnerContainer = styled.div`
// display: flex;
// flex-direction: column;
// `;

const GetStartedButton = styled.button`
border-radius: 25px;
    color: white;
    background: linear-gradient(to bottom, #398fc8 0%, rgb(57 144 255) 100%);
    border: 0px none;
    display: inline-block;
    padding: 10px;
    width: 50%;
    margin: 20px auto;
	a{
		color:white;
	}
`;
const MyGettingStartedContainer = styled.div`
  background: linear-gradient(to bottom, #398fc8 0%, rgb(57 144 255) 100%);
  box-sizing: border-box;
  padding:40px;
  min-height: calc(100vh - 179px);
`;

const MylandingPagealign = styled.div`
  box-sizing: border-box;
  min-height: calc(100vh - 179px);
  display: flex;
  flex-direction: column;
  background: ${(props) => props.background};
  align-items: center;
  justify-content: center;
  gap: 30px
    `;

const GetStartedButton2 = styled.button`
border-radius: 25px;
    color: red;
    background: 'linear-gradient(to bottom, #398fc8 0%, rgb(57 144 255) 100%)';
    border: 0px none;
    display: inline-block;
    padding: 10px;
    width: 50%;
    margin: 40px auto;
	a{
		color:white;
	}
`;

export { LandingPageContainer, ImageCOntainer, GetStartedButton, container, MyGettingStartedContainer, MylandingPagealign };
